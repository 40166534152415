import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

const CopyrightDiv = ({copyright}) =>{
    return (
        <Container id="butter-container-copyright">
            <Col>
                <Row className="justify-content-center">
                    <label style={{ fontWeight:'bold', color:'black' }} >
                        {copyright}
                    </label>
                </Row>
            </Col>
        </Container>
    );
}

export default CopyrightDiv;