import {Row, Button, InputGroup, Jumbotron, Container, FormControl, Badge, Col} from "react-bootstrap";
import React from "react";
import * as ButterParam from "../util/butterparam";
import axios from "axios";


class Configuration extends React.Component {

    UnlockDeeplink = React.createRef();
    DeeplinkUpdateButton = React.createRef();

    UnlockDevListEnppoint = React.createRef();
    DevListEndPointUpdateButton = React.createRef();
    DevListEndPointValue = React.createRef();

    UnlockDevSubmitEnppoint = React.createRef();
    DevSubmitEndPointUpdateButton = React.createRef();
    DevSubmitEndPointValue = React.createRef();

    UnlockLiveListEndpoint = React.createRef();
    LiveListEndpointUpdateButton = React.createRef();
    LiveListEndpointValue = React.createRef();

    UnlockLiveSubmitEndpoint = React.createRef();
    LiveSubmitEndpointUpdateButton = React.createRef();
    LiveSubmitEndpointValue = React.createRef();

    UnlockStagingListEndpoint = React.createRef();
    StagingListEndpointUpdateButton = React.createRef();
    StagingListEndpointValue=React.createRef();

    UnlockStagingSubmitEndpoint = React.createRef();
    StagingSubmitEndpointUpdateButton = React.createRef();
    StagingSubmitEndpointValue=React.createRef();

    UnlockRewardTable = React.createRef();
    RewardTableUpdateButton = React.createRef();
    RewardTableValue=React.createRef();

    UnlockClaimsTable = React.createRef();
    ClaimsTableUpdateButton = React.createRef();
    ClaimsTableValue=React.createRef();

    constructor(props) {
        super(props);
        this.state = {
                deeplink:true
        }
    }

    componentDidMount() {

        this.DeeplinkUpdateButton.current.disabled=true;
        this.DevListEndPointUpdateButton.current.disabled=true;
        this.DevListEndPointValue.current.disabled=true;
        this.DevSubmitEndPointUpdateButton.current.disabled=true;
        this.DevSubmitEndPointValue.current.disabled=true;
        this.LiveListEndpointUpdateButton.current.disabled=true;
        this.LiveListEndpointValue.current.disabled=true;
        this.LiveSubmitEndpointUpdateButton.current.disabled=true;
        this.LiveSubmitEndpointValue.current.disabled=true;
        this.StagingListEndpointUpdateButton.current.disabled=true;
        this.StagingListEndpointValue.current.disabled=true;
        this.StagingSubmitEndpointUpdateButton.current.disabled=true;
        this.StagingSubmitEndpointValue.current.disabled=true;
        this.RewardTableUpdateButton.current.disabled=true;
        this.RewardTableValue.current.disabled=true;
        this.ClaimsTableUpdateButton.current.disabled=true;
        this.ClaimsTableValue.current.disabled=true;

        axios
            .post(ButterParam.ConfigListURL,{
                gid:this.props.gid
            })
            .then((response) => {

                this.setState({
                    deeplink:response.data.deeplinks
                });
                this.DevListEndPointValue.current.value = response.data.devlist;
                this.DevSubmitEndPointValue.current.value = response.data.devsubmit;
                this.StagingListEndpointValue.current.value = response.data.staginglist;
                this.StagingSubmitEndpointValue.current.value = response.data.stagingsubmit;
                this.LiveListEndpointValue.current.value = response.data.livelist;
                this.LiveSubmitEndpointValue.current.value = response.data.livesubmit;
                this.RewardTableValue.current.value = response.data.rewardtable;
                this.ClaimsTableValue.current.value = response.data.claimtable;
            }, (error) => {
                console(error);
            }).catch(console.log);
    }


    unlockBRDevListServer = () =>{
        this.UnlockDevListEnppoint.current.disabled = true;
        this.DevListEndPointUpdateButton.current.disabled=false;
        this.DevListEndPointValue.current.disabled=false;
    }
    updateBRDevListServer = () =>{
        this.UnlockDevListEnppoint.current.disabled = false;
        this.DevListEndPointUpdateButton.current.disabled=true;
        this.DevListEndPointValue.current.disabled=true;
        this.updateConfigs();
    }

    unlockBRDevSubmitServer = () =>{
        this.UnlockDevSubmitEnppoint.current.disabled = true;
        this.DevSubmitEndPointUpdateButton.current.disabled=false;
        this.DevSubmitEndPointValue.current.disabled=false;
    }
    updateBRDevSubmitServer = () =>{
        this.UnlockDevSubmitEnppoint.current.disabled = false;
        this.DevSubmitEndPointUpdateButton.current.disabled=true;
        this.DevSubmitEndPointValue.current.disabled=true;
        this.updateConfigs();
    }

    unlockBRStagingListServer = () =>{
        this.UnlockStagingListEndpoint.current.disabled = true;
        this.StagingListEndpointUpdateButton.current.disabled=false;
        this.StagingListEndpointValue.current.disabled=false;
    }
    updateBRStagingListServer = () =>{
        this.UnlockStagingListEndpoint.current.disabled = false;
        this.StagingListEndpointUpdateButton.current.disabled=true;
        this.StagingListEndpointValue.current.disabled=true;
        this.updateConfigs();
    }

    unlockBRStagingSubmitServer = () =>{
        this.UnlockStagingSubmitEndpoint.current.disabled = true;
        this.StagingSubmitEndpointUpdateButton.current.disabled=false;
        this.StagingSubmitEndpointValue.current.disabled=false;
    }
    updateBRStagingSubmitServer = () =>{
        this.UnlockStagingSubmitEndpoint.current.disabled = false;
        this.StagingSubmitEndpointUpdateButton.current.disabled=true;
        this.StagingSubmitEndpointValue.current.disabled=true;
        this.updateConfigs();
    }

    unlockBRLiveListServer = () =>{
        this.UnlockLiveListEndpoint.current.disabled = true;
        this.LiveListEndpointUpdateButton.current.disabled=false;
        this.LiveListEndpointValue.current.disabled=false;
    }
    updateBRLiveListServer = () =>{
        this.UnlockLiveListEndpoint.current.disabled = false;
        this.LiveListEndpointUpdateButton.current.disabled=true;
        this.LiveListEndpointValue.current.disabled=true;
        this.updateConfigs();
    }

    unlockBRLiveSubmitServer = () =>{
        this.UnlockLiveSubmitEndpoint.current.disabled = true;
        this.LiveSubmitEndpointUpdateButton.current.disabled=false;
        this.LiveSubmitEndpointValue.current.disabled=false;
    }
    updateBRLiveSubmitServer = () =>{
        this.UnlockLiveSubmitEndpoint.current.disabled = false;
        this.LiveSubmitEndpointUpdateButton.current.disabled=true;
        this.LiveSubmitEndpointValue.current.disabled=true;
        this.updateConfigs();
    }

    unlockRewardTable = () =>{
        this.UnlockRewardTable.current.disabled = true;
        this.RewardTableUpdateButton.current.disabled=false;
        this.RewardTableValue.current.disabled=false;
    }
    updateRewardTable = () =>{
        this.UnlockRewardTable.current.disabled = false;
        this.RewardTableUpdateButton.current.disabled=true;
        this.RewardTableValue.current.disabled=true;
        this.updateConfigs();
    }

    unlockClaimTable = () =>{
        this.UnlockClaimsTable.current.disabled = true;
        this.ClaimsTableUpdateButton.current.disabled=false;
        this.ClaimsTableValue.current.disabled=false;
    }
    updateClaimTable = () =>{
        this.UnlockClaimsTable.current.disabled = false;
        this.ClaimsTableUpdateButton.current.disabled=true;
        this.ClaimsTableValue.current.disabled=true;
        this.updateConfigs();
    }





    updateConfig = (updatedState) =>{

        axios
            .post(ButterParam.ConfigUpdateURL,{
                gid:this.props.gid,
                deeplinks:updatedState.deeplink,
                devlist:this.DevListEndPointValue.current.value,
                devsubmit:this.DevSubmitEndPointValue.current.value,
                staginglist:this.StagingListEndpointValue.current.value,
                stagingsubmit:this.StagingSubmitEndpointValue.current.value,
                livelist:this.LiveListEndpointValue.current.value,
                livesubmit:this.LiveSubmitEndpointValue.current.value,
                rewardtable:this.RewardTableValue.current.value,
                claimtable:this.ClaimsTableValue.current.value
            })
            .then((response) => {
                this.setState({
                    deeplink:response.data.deeplinks
                });

                this.DevListEndPointValue.current.value = response.data.devlist;
                this.DevSubmitEndPointValue.current.value = response.data.devsubmit;
                this.StagingListEndpointValue.current.value = response.data.staginglist;
                this.StagingSubmitEndpointValue.current.value = response.data.stagingsubmit;
                this.LiveListEndpointValue.current.value = response.data.livelist;
                this.LiveSubmitEndpointValue.current.value = response.data.livesubmit;
                this.RewardTableValue.current.value = response.data.rewardtable;
                this.ClaimsTableValue.current.value = response.data.claimtable;

            }, (error) => {
                console(error);
            }).catch(console.log);
    }

    updateConfigs = () =>{
        axios
            .post(ButterParam.ConfigUpdateURL,{
                gid:this.props.gid,
                deeplinks:this.state.deeplink,
                devlist:this.DevListEndPointValue.current.value,
                devsubmit:this.DevSubmitEndPointValue.current.value,
                staginglist:this.StagingListEndpointValue.current.value,
                stagingsubmit:this.StagingSubmitEndpointValue.current.value,
                livelist:this.LiveListEndpointValue.current.value,
                livesubmit:this.LiveSubmitEndpointValue.current.value,
                rewardtable:this.RewardTableValue.current.value,
                claimtable:this.ClaimsTableValue.current.value
            })
            .then((response) => {
                this.setState({
                    deeplink:response.data.deeplinks
                });

                this.DevListEndPointValue.current.value = response.data.devlist;
                this.DevSubmitEndPointValue.current.value = response.data.devsubmit;
                this.StagingListEndpointValue.current.value = response.data.staginglist;
                this.StagingSubmitEndpointValue.current.value = response.data.stagingsubmit;
                this.LiveListEndpointValue.current.value = response.data.livelist;
                this.LiveSubmitEndpointValue.current.value = response.data.livesubmit;
                this.RewardTableValue.current.value = response.data.rewardtable;
                this.ClaimsTableValue.current.value = response.data.claimtable;

            }, (error) => {
                console(error);
            }).catch(console.log);
    }

    unlockDeepLink = () => {
        this.UnlockDeeplink.current.disabled=true;
        this.DeeplinkUpdateButton.current.disabled=false;
    }
    updateDeeplinks = () => {
        if(this.state.deeplink === true){
            alert('You are DISABLING the Deeplinks on IPhone Devices!\nPlayers will have to copy their Support ID manually.');
            this.updateConfig({
                 deeplink:false
            });
        }else{
            alert('You are ENABLING the Deeplinks on IPhone Devices!\nNo Support ID Input will be shown for IPhones.');
            this.updateConfig({
                 deeplink:true
            });
        }
        this.UnlockDeeplink.current.disabled=false;
        this.DeeplinkUpdateButton.current.disabled=true;
    }



    getButtonInputGroup = (description,unlockButtonRef, unlockButtonOnClickAction, valueRef,updateButtonRef,updateButtonOnClickAction) =>{
        return (<>
            <Row className="justify-content-start">
                <Badge pill variant="primary">{description}</Badge>
            </Row>
        <Row className="justify-content-center">
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <Button ref={unlockButtonRef} onClick={unlockButtonOnClickAction} variant="dark">Unlock</Button>
                </InputGroup.Prepend>
                <FormControl ref={valueRef} aria-describedby="basic-addon2" />
                <InputGroup.Append>
                    <Button ref={updateButtonRef} onClick={updateButtonOnClickAction} variant="outline-danger">Update</Button>
                </InputGroup.Append>
            </InputGroup>
        </Row></>);
    }


    render() {
        return (
            <Jumbotron>
                <Container>
                    <Row className="justify-content-start">
                        <Badge pill variant="primary">Deeplinking for IPhone Devices</Badge>
                    </Row>
                        <Row className="justify-content-center">
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <Button ref={this.UnlockDeeplink} onClick={this.unlockDeepLink} variant="dark">Unlock</Button>
                                </InputGroup.Prepend>
                                <FormControl
                                    value={this.state.deeplink === true ?"Deeplinks for IPhone is Enabled":"Deeplinks for IPhone is Disabled"}
                                    aria-describedby="basic-addon2"
                                    style={{backgroundColor:this.state.deeplink === true ?'#33FF33':'#FF6666', fontWeight:'bold'}}
                                />
                                <InputGroup.Append>
                                    <Button ref={this.DeeplinkUpdateButton} variant={this.state.deeplink === true ? "outline-danger":"outline-success"} onClick={this.updateDeeplinks} >{this.state.deeplink === true?"Disable":"Enable"}</Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Row>
                        {this.getButtonInputGroup('Skin Listing EndPoint for Butter Dev',this.UnlockDevListEnppoint,this.unlockBRDevListServer,this.DevListEndPointValue,this.DevListEndPointUpdateButton,this.updateBRDevListServer)}
                        {this.getButtonInputGroup('Reward Claim Submission Endpoint for Butter Dev',this.UnlockDevSubmitEnppoint,this.unlockBRDevSubmitServer,this.DevSubmitEndPointValue,this.DevSubmitEndPointUpdateButton,this.updateBRDevSubmitServer)}
                        {this.getButtonInputGroup('Skin Listing EndPoint for Butter Staging',this.UnlockStagingListEndpoint,this.unlockBRStagingListServer,this.StagingListEndpointValue,this.StagingListEndpointUpdateButton,this.updateBRStagingListServer)}
                        {this.getButtonInputGroup('Reward Claim Submission Endpoint for Butter Staging',this.UnlockStagingSubmitEndpoint,this.unlockBRStagingSubmitServer,this.StagingSubmitEndpointValue,this.StagingSubmitEndpointUpdateButton,this.updateBRStagingSubmitServer)}
                        {this.getButtonInputGroup('Skin Listing EndPoint for Butter Live',this.UnlockLiveListEndpoint,this.unlockBRLiveListServer,this.LiveListEndpointValue,this.LiveListEndpointUpdateButton,this.updateBRLiveListServer)}
                        {this.getButtonInputGroup('Reward Claim Submission Endpoint for Butter Live',this.UnlockLiveSubmitEndpoint,this.unlockBRLiveSubmitServer,this.LiveSubmitEndpointValue,this.LiveSubmitEndpointUpdateButton,this.updateBRLiveSubmitServer)}
                        {this.getButtonInputGroup('DynamoDB Table for Rewards',this.UnlockRewardTable,this.unlockRewardTable,this.RewardTableValue,this.RewardTableUpdateButton,this.updateRewardTable)}
                        {this.getButtonInputGroup('DynamoDB Table for Claim Submissions',this.UnlockClaimsTable,this.unlockClaimTable,this.ClaimsTableValue,this.ClaimsTableUpdateButton,this.updateClaimTable)}
                </Container>
            </Jumbotron>
        );
    }
}



export default Configuration;