import React from "react";
import {Container, Jumbotron, Row, Dropdown, Badge, Col, Button} from "react-bootstrap";
import '../css/Stats.css';
import axios from "axios";
import * as ButterParam from "../util/butterparam";

let rewards = [];

const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
        let sortableItems = [...items];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }else{
            console.log("No rewards sent - not cached")
        }
        return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
};

const RewardsTable = (props) => {
    const { items, requestSort, sortConfig } = useSortableData(props.rewards);
    const getClassNamesFor = (id) => {

        if (!sortConfig) {
            return;
        }
        return sortConfig.key === id ? sortConfig.direction : undefined;
    };

    return (
        <>
            <caption style={{whiteSpace: 'nowrap', overflow: 'hidden'}}>Updated@{props.refreshed}</caption>
        <table>
            <thead>
            <tr>
                <th>
                    <button style={props.rewardId !== '' ? {backgroundColor: 'lightsalmon'}:{}} type="button" onClick={() => requestSort('rewardid')} className={getClassNamesFor('rewardid')} >
                        Reward ID
                    </button>
                </th>
                <th>
                    <button style={props.status !== '' ? {backgroundColor: 'lightsalmon'}:{}} type="button" onClick={() => requestSort('status')} className={getClassNamesFor('status')} >
                        Status
                    </button>
                </th>
                <th>
                    <button style={props.env !== '' ? {backgroundColor: 'lightsalmon'}:{}} type="button" onClick={() => requestSort('environment')}  className={getClassNamesFor('environment')} >
                        Environment
                    </button>
                </th>
                <th>
                    <button type="button" onClick={() => requestSort('Start')} className={getClassNamesFor('Start')} >
                        Start Date
                    </button>
                </th>
                <th>
                    <button type="button" onClick={() => requestSort('End')} className={getClassNamesFor('End')} >
                        End Date
                    </button>
                </th>
                <th>
                    <button type="button" onClick={() => requestSort('Success')} className={getClassNamesFor('Success')} >
                        Success
                    </button>
                </th>
                <th>
                    <button type="button" onClick={() => requestSort('Fail')}  className={getClassNamesFor('Fail')} >
                        Failure
                    </button>
                </th>
                <th>
                    <button type="button" onClick={() => requestSort('Redundant')} className={getClassNamesFor('Redundant')} >
                        AlreadyClaimed
                    </button>
                </th>
                <th>
                    <button type="button" className={getClassNamesFor('Skins')} >
                        Skins
                    </button>
                </th>
                <th>
                    <button style={props.creator !== '' ? {backgroundColor: 'lightsalmon'}:{}}  type="button" className={getClassNamesFor('Creator')} >
                        Created By
                    </button>
                </th>
            </tr>
            </thead>
            <tbody>
            {items
                .filter(reward => reward.info !== undefined)
                .map((reward) => {
                    reward.info['rewardid'] =reward.rewardid;
                    let isExpired = new Date() > new Date(reward.info.endDate);
                    reward.info['status'] = isExpired ? "Ended":"Active";
                    reward['status'] = reward.info['status'];
                    reward['environment'] = reward.info['environment'];
                    reward['Start'] = reward.info['startDate'];
                    reward['End'] = reward.info['endDate'];

                    if(reward.info['success'] === undefined){
                        reward.info['success'] = 0;
                        reward['Success'] = 0;
                    }else{
                        reward['Success'] = reward.info['success'];
                    }
                    if(reward.info['fail'] === undefined){
                        reward.info['fail'] = 0;
                        reward['Fail'] = 0;
                    }else{
                        reward['Fail'] =  reward.info['fail'];
                    }
                    if(reward.info['redundant'] === undefined){
                        reward.info['redundant'] = 0;
                        reward['Redundant'] = 0;
                    }else{
                        reward['Redundant'] = reward.info['redundant'];
                    }
                    return reward.info;
                })
                .filter(
                        reward =>
                                (
                                          reward.rewardid.toLowerCase().includes(props.rewardId.toLowerCase()) &&
                                          reward.status.toLowerCase().includes(props.status.toLowerCase()) &&
                                          reward.environment.toLowerCase().includes(props.env.toLowerCase()) &&
                                          reward.creator.toLowerCase().startsWith(props.creator.toLowerCase())
                                ))
                .map((item) => (

                <tr key={item.rewardid}>
                    <td style={{whiteSpace: 'nowrap'}}>{item.rewardid}</td>
                    <td>{item.status}</td>
                    <td>{item.environment}</td>
                    <td style={{whiteSpace: 'nowrap'}}>{item.startDate}</td>
                    <td style={{whiteSpace: 'nowrap'}}>{item.endDate}</td>
                    <td>{item.success}</td>
                    <td>{item.fail}</td>
                    <td>{item.redundant}</td>
                    <td><Dropdown>
                        <Dropdown.Toggle variant="Secondary">
                            {item.rewardSkin.split(",").length} Skins
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                        {item.rewardSkin.split(",").map(skin =>(<Dropdown.Item href="#/action-1">{skin}</Dropdown.Item>))}
                        </Dropdown.Menu>

                    </Dropdown></td>
                    <td>{item.creator}</td>
                </tr>
            ))}
            </tbody>
        </table>
            </>
    );
};



class RewardStats extends React.Component {

    RefreshButton = React.createRef();

    constructor(props) {
        super(props);
        this.state = {search:'', status:'', env:'', creator:'', refreshed:''}
    }

    componentDidMount() {
        console.log(`Attempting to retrieve stats from ${ButterParam.Environment}` );
        axios
            .post(ButterParam.RewardStatsListURL,{
                gid:this.props.gid
            })
            .then((response) => {

                rewards = response.data.message.Items;
                this.setState({
                    search: this.state.search,
                    status:this.state.status,
                    env:this.state.env,
                    creator:this.state.creator,
                    refreshed: response.data.refreshed
                });
                if(rewards !== []){
                    console.log(`Reward List Successfully Updated.`);
                }else{
                    console.log(`There is no cached data for rewards.`);
                }

        }, (error) => {
            console(error);
        }).catch(console.log);

    }

    handleRewardID(event) {
         this.setState(
             {
                    search: event.target.value,
                    status:this.state.status,
                    env:this.state.env,
                    creator:this.state.creator,
                    refreshed: this.state.refreshed
                    }
             )
    }
    handleStatus(event) {
        this.setState(
            {
                search: this.state.search,
                status:event.target.value,
                env:this.state.env,
                creator:this.state.creator,
                refreshed: this.state.refreshed
            }
        )
    }
    handleEnv(event) {
        this.setState(
            {
                search: this.state.search,
                status:this.state.status,
                env:event.target.value,
                creator:this.state.creator,
                refreshed: this.state.refreshed
            }
        )
    }
    handleCreator(event) {

        this.setState(
            {
                search: this.state.search,
                status:this.state.status,
                env:this.state.env,
                creator:event.target.value,
                refreshed: this.state.refreshed
            }
        )
    }

    handleRefresh(){
        this.RefreshButton.current.setAttribute('disabled', true);
        axios
            .post(ButterParam.RewardRefreshStatsURL,{
                gid:this.props.gid
            })
            .then((response) => {
                if(response.data.message === "OK"){
                    console.log("Successfully refreshed on backend.")
                    axios
                        .post(ButterParam.RewardStatsListURL,{
                            gid:this.props.gid
                        })
                        .then((response) => {

                            rewards = response.data.message.Items;
                            this.setState({
                                search: this.state.search,
                                status:this.state.status,
                                env:this.state.env,
                                creator:this.state.creator,
                                refreshed: response.data.refreshed
                            });
                            if(rewards !== []){
                                console.log(`Reward List Successfully Updated.`);
                            }else{
                                console.log(`There is no cached data for rewards.`);
                            }
                        }, (error) => {
                            console(error);
                        }).catch(console.log);
                }else{
                    alert("Authentication Error - Please contact developer");
                }
            }, (error) => {
                alert(error);
                console(error);
            }).catch(console.log);

        // this.RefreshButton.current.innerHTML = "Please do not use this button frequently";
        //TODO - Refresh call
        // alert("Please avoid using this button frequently");
    }

    render(){

        return(
            <Jumbotron>
                <Container>
                    <Row className="justify-content-center">
                        <Col>
                            <Badge variant="danger">Filter by Reward ID</Badge>
                            <div className="input-group">
                                <input onChange={this.handleRewardID.bind(this)} type="text" className="form-control" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col>
                            <Badge variant="warning">Filter by Status</Badge>
                            <div className="input-group">
                                <input onChange={this.handleStatus.bind(this)} type="text" className="form-control" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col>
                            <Badge variant="primary">Filter by Environment</Badge>
                            <div className="input-group">
                                <input onChange={this.handleEnv.bind(this)} type="text" className="form-control" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col>
                            <Badge variant="success">Filter by Creator</Badge>
                            <div className="input-group">
                                <input onChange={this.handleCreator.bind(this)} type="text" className="form-control" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col>
                        <Badge variant="danger">Manual Data Refresh</Badge>
                        <div className="input-group">
                            <button ref={this.RefreshButton} onClick={this.handleRefresh.bind(this)} className="btn btn-danger">Refresh</button>
                        </div>
                    </Col>
                    </Row>
                    <Row className="justify-content-center">
                    <RewardsTable rewards={rewards} rewardId={this.state.search} status={this.state.status} env={this.state.env} creator={this.state.creator} refreshed={this.state.refreshed}/>
                    </Row>
                </Container>
            </Jumbotron>
        );
    }

}

export default RewardStats;