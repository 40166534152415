import React from 'react';
import {Row} from "react-bootstrap";
import * as ButterParam from "../util/butterparam";

class Linkimage extends React.Component {


    constructor(props) {
        super(props);
    }



    render() {
        return (
            <>
                <Row className="justify-content-center" >
                <b style={{fontFamily: ButterParam.ClaimFontFamily, fontWeight:ButterParam.ClaimFontWeight,
                    color:ButterParam.FontColorNormal }}>Don’t have the game yet?</b>
                </Row>
                <Row className="justify-content-center" >
                    <b style={{fontFamily: ButterParam.ClaimFontFamily, fontWeight:ButterParam.ClaimFontWeight,
                        color:ButterParam.FontColorNormal}}>Download Now!</b>
                </Row>
                <Row className="justify-content-center" >
                    <div style={{
                        height: '144px',
                        width : ButterParam.MaxImageWidth,
                        backgroundSize: 'contain',
                        backgroundRepeat:'no-repeat',
                        backgroundImage: `url(${this.props.iconurl === undefined ? "https://dwac3n99paaqb.cloudfront.net/Razer_Arcade.png" :this.props.iconurl })`}}
                        onClick={() => window.open("https://apple.co/3a3WkXg", '_blank')}>
                    </div>
                </Row>
            </>
        );
    }
}
export default Linkimage;