import {Col, Container, Row} from "react-bootstrap";
import React from "react";


//TODO - make the contents customizable
const ErrorDiv = () =>{
    return (
            <Container id="butter-container-error">
                <Col>
                    <Row className="justify-content-center">
                        <label>An Error Occured!</label>
                    </Row>
                    <Row className="justify-content-center">
                        <p>Please contact support@mightybeargames.com with the link you used</p>
                    </Row>
                </Col>
            </Container>
    );
}

export default ErrorDiv;