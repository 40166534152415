import React from 'react';
import {Row} from "react-bootstrap";
import * as ButterParam from "../util/butterparam";

//TODO - make the image customizable
class SupportV2 extends React.Component {

    render() {

        return (
            <>
                <Row className="justify-content-center" >
                    <div style={{
                        height: '500px',
                        width : ButterParam.MaxImageWidth,
                        backgroundSize: 'contain',
                        backgroundRepeat:'no-repeat',
                        backgroundImage: `url("https://dwac3n99paaqb.cloudfront.net/how_to_find_supportid.png")`}}>
                    </div>
                </Row>
            </>
        );
    }
}
export default SupportV2;