import {Col, Container, Row} from "react-bootstrap";
import React from "react";

const LoginSuccess = (props) =>{
    return (
            <Container>
                <Col>
                    <Row className="justify-content-center">
                        <label>Login Success! </label>
                        <p>{props.scope}</p>
                    </Row>
                    <br/><br/><br/>
                </Col>
            </Container>
    );
}

export default LoginSuccess;