import React from "react";
import ReactPixel from "react-facebook-pixel";
import {Badge} from "react-bootstrap";

export const GID = 'gid';
export const LinkAlreadyClaimed = 444;
export const LinkAlreadyClaimedText1 = 'You have already used this reward link before!';
export const LinkAlreadyClaimedText2 = 'Wait for new rewards to claim more!';

export const SkinOrPlayerInvalid = 555;

export const SkinAlreadyInInbox = 666;
export const ClaimSuccess = 200;
export const ServerBusyError = 503;
export const PlayerNeverOnlineError = 404;

export const AlreadyClaimedResult = 'Already Claimed!';
export const PlayerNotOnlineResult = 'Not Online Player!';
export const RewardInInboxResult = 'Already in Inbox!';
export const ServerErrorResult = 'Server Error!';
export const InvalidIdResult = 'Invalid ID';




export const SkinAlreadyInInbox1 = 'Your reward is already in your inbox!';
export const SkinAlreadyInInbox2 = 'Open Butter Royale to claim it!';

export const ClaimFontFamily = 'Nunito';
export const ClaimFontWeight = 'bold';


export const MaxImageWidth = '350px';

export const FontColorNormal = '#5B606D';
export const FontColorLighter  = '#B9C4D2';
export const FontColorPositive = '#95E000';
export const FontColorNegative = '#CB2141';

export const getEmptyLines = (times) => {
    let lines = []
    for (let i = 0; i < times; i++) {
        lines.push(<br key={i} />);
    }
    return lines;
}

export const AdminParams = {

    Defaults : {
        OriginChunk : {
            Image: 'Upload Origin Background Image',
            SupportLabel: 'Default Value : "Enter Your Support ID"',
            SupportID : 'Default Value : "XXX-XXX-XXX"',
            SubmitButton: 'Default Value : "Submit!"',
            ButtonColor : 'Default Button Color'

        },
        ClaimChunk :{
            Image : 'Upload Claim Background Image',
            SubmitButton : 'Default Value : "Claimed!"',
            ButtonColor : 'Default Button Color'
        },
        ActionChunk : {
            Image: 'Upload Action Background Image',
            UpperText : 'Text for upper part',
            LowerText : 'Text for lower part',
            SubmitButton : 'Action Button Text',
            ButtonColor : 'Default Button Color'
        }
    },
    GoogleClientId : process.env.REACT_APP_GOOGLE_CLIENT_ID,
    MissingFieldMsg : 'Missing Data on Your Submission\nPlease Fill the Highlighted Fields!',
    RewardSkinListPlaceholder : 'Skin ID for Butter Royale',
    MissingFieldColor : '#fcd2d2',
    FBTrackingPixelID : process.env.REACT_APP_FBTRACKINGPIXED_ID,
    TrackEventData : (env, event, rewardId,skin) => {
        if(env === 'Live'){
            ReactPixel
                .trackSingle(
                    process.env.REACT_APP_FBTRACKINGPIXED_ID,
                    rewardId+"|"+ skin+"|" +event)
        }
    }
}



export const PlayerPage  = {
    BackgroundImage : 'page_background',
    ClaimBoxImage   : 'claimbox_img',
    Description     : 'description',
    StartDate       : 'start',
    EndDate         : 'end',
    SupportIDPlaceholder : 'support_id_placeholder',
    ClaimButtonText : 'claim_button_text',
    ClaimButtonColor : 'claim_button_color',
    ClaimSuccessText : 'claim_success_text',
    ClaimSuccessColor : 'claim_success_color',
    ClaimFailureTxt  : 'claim_failed_text',
    ClaimFailureColor : 'claim_failed_color',
    InvalidIdImage   : 'invalid_id_image',
    FailedAttemptImage : 'attempt_failed_image',
    SupportHelpImage   : 'support_help_image',
    ActionImage        : 'action_image',
    ActionEndpoint     : 'action_endpoint',
    Environment        : 'environment',
    Creator            : 'creator',
    Reward             : 'reward',
    CustomUrl          : 'customurl',
    ArcadeIconImage   : 'appstoreicon',
    RewardClaimHardCap: 'remainingclaims'
}

export const Environment = process.env.REACT_APP_ENVIRONMENT;//"local";//live/local/dev
export const SkinListURL = process.env.REACT_APP_SKINLIST_URL;//set in DockerFile or in .env on your local
export const CreateRewardURL = process.env.REACT_APP_CREATEREWARD_URL;
export const ListRewardURL = process.env.REACT_APP_LISTREWARD_URL;
export const SubmitClaimURL = process.env.REACT_APP_SUBMITCLAIM_URL;
export const RewardRetrieveURL = process.env.REACT_APP_REWARDRETRIEVE_URL;
export const RewardStatsListURL = process.env.REACT_APP_REWARDSTATSLIST_URL;
export const RewardRefreshStatsURL = process.env.REACT_APP_REWARDREFRESHSTATS_URL;
export const RewardShareRootURL = process.env.REACT_APP_SHAREROOT_URL;
export const ConfigListURL = process.env.REACT_APP_CONFIG_LIST_URL;
export const ConfigUpdateURL = process.env.REACT_APP_CONFIG_UPDATE_URL;;
export const EnvironmentFlagBadge= process.env.REACT_APP_ENVIRONMENT === "live" ?
                                                        <></> : process.env.REACT_APP_ENVIRONMENT === "dev" ?
                                                                        <Badge variant= "danger"><h3>Development</h3></Badge> :
                                                                        <Badge variant= "danger"><h3>Local</h3></Badge>;
