import React from 'react';
import {Row} from "react-bootstrap";


class ClaimSuccess extends React.Component {
    //TODO Dynamic Success Image Handling
    render() {
        return (
            <>
                <Row className="justify-content-center" >
                    <img src='https://dwac3n99paaqb.cloudfront.net/claim_success_mod.png'width='320px' style={{ alignSelf: 'flex-start' }} />
                </Row>
                <Row className="justify-content-center" >
                    <img src={this.props.layout.actionImage} width='320px' style={{ alignSelf: 'flex-start' }} onClick={this.props.handleAction}/>
                </Row>
                </>
        );
    }
}
export default ClaimSuccess;