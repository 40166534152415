import React from 'react';
import {Row, Badge} from "react-bootstrap";
import * as ButterParam from "../util/butterparam";

//TODO - make the image customizable
class InvalidSupportID extends React.Component {
    render() {
        return (
                <Row className="justify-content-center" >
                    <Row className="justify-content-center" style={{
                        height: '599px',
                        width : ButterParam.MaxImageWidth,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundImage: `url(https://dwac3n99paaqb.cloudfront.net/invalid_support_id.png)`}}>
                        <div>
                            {ButterParam.getEmptyLines(10)}
                            <a href='' onClick={(e) => {e.preventDefault();this.props.showSupport();}}><Badge style={{fontFamily: ButterParam.ClaimFontFamily,
                                fontWeight:ButterParam.ClaimFontWeight,
                                fontColor: ButterParam.FontColorNegative,
                                fontSize: '14px'}}>How to find your support ID?</Badge></a>
                        </div>

                    </Row>
                </Row>
        );
    }
}
export default InvalidSupportID;