import { Col, Container, Jumbotron, Form, Row, Popover, OverlayTrigger,Tabs, Tab, Badge } from "react-bootstrap";
import React from "react";
import ButterRoyale from "../util/butterroyale";
import {GoogleLogin, GoogleLogout} from 'react-google-login';
import axios from "axios";
import { SketchPicker } from 'react-color';
import { usePromiseTracker } from "react-promise-tracker";
import { trackPromise } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';
import * as ButterParam from "../util/butterparam";
import DateTimePicker from 'react-datetime-picker';
import RewardStats from "./stats";
import Configuration from "./serverconfigs";

const Butter = new ButterRoyale();


const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();

    return (
        promiseInProgress &&
           <div style={{ width: "100%", height: "100", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
            </div>
    );
}


const LinkToShare = ({address}) => {

    return (
        address.length === 0 ? "":
            <Row className="justify-content-center">
                <Col>
                    <div className="input-group">
                        <Col>
                            <br/>
                            <Row className="justify-content-center">
                                <label>You can share below link:</label>
                            </Row>
                            <br />
                            <Row className="justify-content-center">
                                <label style={{backgroundColor:'#7ed321'}}><strong>{address}</strong></label>
                            </Row>
                            <br/>
                        </Col>
                    </div>
                </Col>
            </Row>
    );
}
//TODO - Reward Page Preview
class AdminPageV2 extends React.Component {

    RewardData = React.createRef();
    StartDateRef = React.createRef();
    EndDateRef = React.createRef();

    StartDateLabel = React.createRef();
    EndDateLabel = React.createRef();


    constructor(props) {
        super(props);
        let today = new Date();
        this.state = { authenticated: false,  gid: '',  linkReady:'',  startDate: today,  endDate : today,  skins:[] }
    }


    LoginSuccess = (loginResponse) => {
        this.setState(
            { authenticated: true,  gid: loginResponse['tokenId'],  linkReady: this.state.linkReady,
                   startDate: this.state.startDate, endDate : this.state.endDate, skins : this.state.skins
                });
    }

    LoginFail = (loginResponse) => {
        this.setState(
            { authenticated: false,  gid:'', linkReady: this.state.linkReady,
                startDate: this.state.startDate, endDate : this.state.endDate, skins : this.state.skins
            });
        console.log("Failed Login - " + JSON.stringify(loginResponse));
    }

    LogoutSuccess = (logoutResponse) => {
        this.setState(
            { authenticated: false,  gid:'', linkReady: this.state.linkReady,
            startDate: this.state.startDate, endDate : this.state.endDate,  skins : this.state.skins
        });
        console.log("Successfully Logged Out - " + JSON.stringify(logoutResponse));
    }


    UpdateStartDate = (startDay) =>{
        this.setState(
            { authenticated: this.state.authenticated, gid:this.state.gid, linkReady: this.state.linkReady,
                startDate: startDay, endDate : this.state.endDate,  skins : this.state.skins
            });
    }


    UpdateEndDate = (endDay) =>{
        this.setState(
            { authenticated: this.state.authenticated,  gid:this.state.gid, linkReady: this.state.linkReady,
            startDate: this.state.startDate, endDate : endDay, skins : this.state.skins});
    }
    GetCreatedRewards = (rewardId) =>{
        axios
            .post(
                ButterParam.ListRewardURL,{
            rewardId: rewardId
        }).then((response) => {
            let rewardObject = JSON.parse(response.data.message)['Item'];



            this.UpdateStartDate(new Date((rewardObject["info"]["startDate"])));
            this.UpdateEndDate(new Date((rewardObject["info"]["endDate"])));


            //TODO this breaks
            this.RewardData.current.RewardSkin.current.value = rewardObject['info']['rewardSkin'];
            this.RewardData.current.ClaimSuccessText.current.value = rewardObject['info']['claimSuccessText'];
            this.RewardData.current.ClaimButtonText.current.value = rewardObject['info']['claimButtonText'];
            this.RewardData.current.ActionEndpoint.current.value = rewardObject['info']['actionUrl'];
            this.RewardData.current.ClaimFailureColor.current.value = rewardObject['info']['claimFailedTextColor'];
            this.RewardData.current.Description.current.value  = rewardObject['info']['description'];
            this.RewardData.current.Environment.current.value =  rewardObject['info']['environment'];
            this.RewardData.current.ClaimFailureText.current.value = rewardObject['info']['claimFailureText'];
            this.RewardData.current.SupportIdPlaceholder.current.value = rewardObject['info']['supportIdPlaceholder'];
            this.RewardData.current.ClaimButtonColor.current.value = rewardObject['info']['claimButtonColor'];
            this.RewardData.current.ClaimSuccessColor.current.value = rewardObject['info']['claimSuccessColor'];
            this.RewardData.current.CustomURL.current.value = rewardId;

            this.RewardData.current.PageBackgroundImageLabel.current.innerHTML = rewardObject['info']['pageBackground'];
            this.RewardData.current.ClaimBoxImageLabel.current.innerHTML = rewardObject['info']['claimBox'];
            this.RewardData.current.ActionImageLabel.current.innerHTML = rewardObject['info']['actionImage'];
            this.RewardData.current.SupportHowToLabel.current.innerHTML = rewardObject['info']['supportHelpImage'];
            this.RewardData.current.SupportIdInvalidImage.current.innerHTML =     rewardObject['info']['invalidIdImage'];



            },
            (error) => {alert(error);}).catch(alert);

    }

    GetDatePicker = (dateRef, onChange, value, minDate) => {
        return (
            <DateTimePicker format="y/MM/dd HH:mm:ss" ref = {dateRef} minDate={minDate} onChange={onChange}
                            value={value} maxDetail="second" disableClock={true}
            />

        );
    }


    IsInvalidFileRef = (fileRef, validationRef) => fileRef.current.files.length === 0 && validationRef.current.innerHTML.includes('Upload');
    IsInvalidLengthRef = (elementRef) => (elementRef.current.value.length === 0);
    ColorCheck = (elementRef) =>{
        if(this.IsInvalidLengthRef(elementRef)){
            elementRef.current.style.backgroundColor = ButterParam.AdminParams.MissingFieldColor;
        }else{
            elementRef.current.style.backgroundColor = "";
        }
    }


    CreateReward = () =>{
        //TODO add invalidation for the edit operation
        let invalidData = (
            this.RewardData.current.PageBackgroundImage.current.files.length === 0 ||
            this.RewardData.current.ClaimBoxImage.current.files.length === 0 ||
            this.RewardData.current.ActionImage.current.files.length === 0 ||
            this.RewardData.current.ActionEndpoint.current.value.length === 0 ||
            this.RewardData.current.Environment.current.value.length === 0 ||
            this.RewardData.current.RewardSkin.length === 0 ||
            this.RewardData.current.Description.current.value.length === 0 ||
            (this.state.startDate.getTime() >= this.state.endDate.getTime())
        );

        if(invalidData === true){
            alert('Please Fix All Missing Mandatory Fields');
            return;

        }else{

            let formdata = new FormData();
            if(this.RewardData.current.PageBackgroundImage.current.files.length === 0){
                formdata.append(ButterParam.PlayerPage.BackgroundImage, this.RewardData.current.PageBackgroundImageLabel.current.innerHTML);
            }else{
                formdata.append(ButterParam.PlayerPage.BackgroundImage, this.RewardData.current.PageBackgroundImage.current.files[0]);
            }
            if(this.RewardData.current.ClaimBoxImage.current.files.length === 0){
                formdata.append(ButterParam.PlayerPage.ClaimBoxImage, this.RewardData.current.ClaimBoxImageLabel.current.innerHTML);
            }else{
                formdata.append(ButterParam.PlayerPage.ClaimBoxImage, this.RewardData.current.ClaimBoxImage.current.files[0]);
            }
            if(this.RewardData.current.ActionImage.current.files.length === 0){
                formdata.append(ButterParam.PlayerPage.ActionImage, this.RewardData.current.ActionImageLabel.current.innerHTML);
            }else{
                formdata.append(ButterParam.PlayerPage.ActionImage, this.RewardData.current.ActionImage.current.files[0]);
            }

            if(!(this.RewardData.current.ActionEndpoint.current.value + "" ).startsWith("https")){
                formdata.append(ButterParam.PlayerPage.ActionEndpoint,"https://" +this.RewardData.current.ActionEndpoint.current.value);
            }else{
                formdata.append(ButterParam.PlayerPage.ActionEndpoint,this.RewardData.current.ActionEndpoint.current.value);
            }
            formdata.append(ButterParam.PlayerPage.Environment,this.RewardData.current.Environment.current.value);
            formdata.append(ButterParam.PlayerPage.Description,this.RewardData.current.Description.current.value);

            formdata.append(ButterParam.PlayerPage.Reward,this.RewardData.current.RewardSkin);


            formdata.append(ButterParam.PlayerPage.EndDate,this.state.endDate.toISOString());
            formdata.append(ButterParam.PlayerPage.StartDate,this.state.startDate.toISOString());


            if(this.RewardData.current.SupportIdPlaceholder.current.value.length !== 0){
                formdata.append(ButterParam.PlayerPage.SupportIDPlaceholder,this.RewardData.current.SupportIdPlaceholder.current.value);
            }
            if(this.RewardData.current.ClaimButtonText.current.value.length !== 0){
                formdata.append(ButterParam.PlayerPage.ClaimButtonText,this.RewardData.current.ClaimButtonText.current.value);
            }
            if(this.RewardData.current.ClaimButtonColor.current.value.length !== 0){
                formdata.append(ButterParam.PlayerPage.ClaimButtonColor,this.RewardData.current.ClaimButtonColor.current.value);
            }
            if(this.RewardData.current.ClaimSuccessText.current.value.length !== 0){
                formdata.append(ButterParam.PlayerPage.ClaimSuccessText,this.RewardData.current.ClaimSuccessText.current.value);
            }
            if(this.RewardData.current.ClaimSuccessColor.current.value.length !== 0){
                formdata.append(ButterParam.PlayerPage.ClaimSuccessColor,this.RewardData.current.ClaimSuccessColor.current.value);
            }
            if(this.RewardData.current.ClaimFailureText.current.value.length !== 0){
                formdata.append(ButterParam.PlayerPage.ClaimFailureTxt,this.RewardData.current.ClaimFailureText.current.value);
            }
            if(this.RewardData.current.ClaimFailureColor.current.value.length !== 0){
                formdata.append(ButterParam.PlayerPage.ClaimFailureColor,this.RewardData.current.ClaimFailureColor.current.value);
            }
            if(this.RewardData.current.ClaimFailureImage.current.value.length !== 0){
                formdata.append(ButterParam.PlayerPage.FailedAttemptImage,this.RewardData.current.ClaimFailureImage.current.value);
            }

            if(this.RewardData.current.CustomURL.current.value.length !== 0){
                formdata.append(ButterParam.PlayerPage.CustomUrl,this.RewardData.current.CustomURL.current.value);
            }


            if(this.RewardData.current.ClaimFailureImage.current.files.length === 0){
                formdata.append(ButterParam.PlayerPage.FailedAttemptImage, this.RewardData.current.ClaimFailureImageLabel.current.innerHTML);
            }else{
                formdata.append(ButterParam.PlayerPage.FailedAttemptImage, this.RewardData.current.ClaimFailureImage.current.files[0]);
            }
            if(this.RewardData.current.SupportIdInvalidImage.current.files.length === 0){
                formdata.append(ButterParam.PlayerPage.InvalidIdImage, this.RewardData.current.SupportIdInvalidImage.current.innerHTML);
            }else{
                formdata.append(ButterParam.PlayerPage.InvalidIdImage, this.RewardData.current.SupportIdInvalidImage.current.files[0]);
            }
            if(this.RewardData.current.SupportHowTo.current.files.length === 0){
                formdata.append(ButterParam.PlayerPage.SupportHelpImage, this.RewardData.current.SupportHowToLabel.current.innerHTML);
            }else{
                formdata.append(ButterParam.PlayerPage.SupportHelpImage, this.RewardData.current.SupportHowTo.current.files[0]);
            }

            if(this.RewardData.current.ArcadeIconImage.current.files.length === 0){
                formdata.append(ButterParam.PlayerPage.ArcadeIconImage, this.RewardData.current.ArcadeIconImageLabel.current.innerHTML);
            }else{
                formdata.append(ButterParam.PlayerPage.ArcadeIconImage, this.RewardData.current.ArcadeIconImage.current.files[0]);
            }
            formdata.append(ButterParam.GID,this.state.gid);

            if(this.RewardData.current.ClaimLimitInput.current.value !==undefined){
                formdata.append(ButterParam.PlayerPage.RewardClaimHardCap, this.RewardData.current.ClaimLimitInput.current.value);
            }


            trackPromise(
            axios.post(ButterParam.CreateRewardURL, formdata, {
                onUploadProgress: (ProgressEvent) => {
                    let progress = Math.round(ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
                    console.log(progress);
                }
            })).then(res=>{
                this.setState(
                    {
                    authenticated: this.state.authenticated, gid:this.state.gid, linkReady: ButterParam.RewardShareRootURL + res.data,
                        startDate: this.state.startDate, endDate : this.state.endDate
                    });
            });

        }
    }


    render() {
        return (
            <>
                {ButterParam.EnvironmentFlagBadge}
                {this.state.authenticated === false ?
                    <>
                        <Row className="justify-content-center">
                                <GoogleLogin clientId={ButterParam.AdminParams.GoogleClientId} buttonText="Sign In with Google Account"
                                    onSuccess={this.LoginSuccess} onFailure={this.LoginFail} isSignedIn={true}
                                />
                        </Row>

                    </>
                    :
                    <>
                        <Row className="justify-content-center">
                            <GoogleLogout clientId={ButterParam.AdminParams.GoogleClientId} buttonText="Sign Out" onLogoutSuccess={this.LogoutSuccess} />
                        </Row>
                        <AdminUI gid={this.state.gid}
                            startDateBox = {
                                <Col style={{position:'relative', zIndex:9999}}>
                                <Row ref = {this.StartDateLabel} className="justify-content-center">
                                    <label>Start Date</label>
                                </Row>
                                <Row style={{zIndex:9999}} className="justify-content-center">
                                    {this.GetDatePicker(this.StartDateRef,this.UpdateStartDate, this.state.startDate, new Date())}
                                </Row>
                                <Row className="justify-content-center">
                                    <Badge variant={this.state.startDate <= this.state.endDate ? "success" : "danger"}>{this.state.startDate > this.state.endDate ? "Mandatory" : "Okay"}</Badge>
                                </Row>
                            </Col>}
                            endDateBox = {
                                <Col style={{position:'relative', zIndex:9999}}>
                                <Row  ref = {this.EndDateLabel} className="justify-content-center">
                                    <label>End Date</label>
                                </Row>
                                <Row className="justify-content-center">
                                    {this.GetDatePicker(this.EndDateRef,this.UpdateEndDate, this.state.endDate, (this.state.startDate.getTime() >= new Date())?this.state.startDate:new Date() )}
                                </Row>
                                <Row className="justify-content-center">
                                    <Badge variant={this.state.startDate < this.state.endDate ? "success" : "danger"}>{this.state.startDate >= this.state.endDate ? "Mandatory" : "Okay"}</Badge>
                                </Row>
                            </Col>}
                            ref={this.RewardData}
                            rewards={this.state.rewards}
                            rewardRetrieve={this.GetCreatedRewards}
                            rewardSubmit={this.CreateReward}
                            link={this.state.linkReady}
                            gid = {this.state.gid}
                        />
                    </>
                }

            </>
        );
    }
}


class AdminUI extends React.Component {

    constructor(props) {
        super(props);
        this.state = { pageBgImg : '',  claimBox : '', actionImg: '', invalidImg : '', failedImg : '',
            supportImg : '', start:new Date(), end:new Date(), skins: [], actionUrl: '', environment: '',
            skin : '', description: '', arcadeicon:''
        }
    }


    componentDidMount() {
        axios
            .post(
                ButterParam.SkinListURL,{ gid: this.props.gid}).then((response) => {
            this.setState(
                {   pageBgImg : this.state.pageBgImg, claimBox : this.state.claimBox,
                          actionImg : this.state.actionImg, invalidImg : this.state.invalidImg,
                          failedImg : this.state.failedImg, supportImg : this.state.supportImg,
                          actionUrl: this.state.actionUrl, environment: this.state.environment,
                          skin : this.state.skin, description: this.state.description,
                          start : this.state.start, end   : this.state.end,
                          skins:(response.data + '').split(","),
                          arcadeicon: this.state.arcadeicon
                }
            );
        }, (error) => {
            console.log(error);
        }).catch(console.log);
    }


    PageBackgroundImage = React.createRef();
    PageBackgroundImageLabel = React.createRef();
    ClaimBoxImage  = React.createRef();
    ClaimBoxImageLabel  = React.createRef();
    ActionImage  = React.createRef();
    ActionImageLabel  = React.createRef();
    ActionEndpoint  = React.createRef();
    Environment  = React.createRef();
    Description = React.createRef();
    SupportIdPlaceholder = React.createRef();
    ClaimButtonText = React.createRef();
    ClaimButtonColor = React.createRef();
    ClaimSuccessText = React.createRef();
    ClaimSuccessColor = React.createRef();
    ClaimFailureText = React.createRef();
    ClaimFailureColor = React.createRef();
    SupportIdInvalidImage = React.createRef();
    SupportIdInvalidImageLabel = React.createRef();
    ClaimFailureImage = React.createRef();
    ClaimFailureImageLabel = React.createRef();
    SupportHowTo = React.createRef();
    SupportHowToLabel = React.createRef();
    RewardRetrieve = React.createRef();
    RewardSkin = React.createRef();
    CustomURL = React.createRef();

    ArcadeIconImage = React.createRef();
    ArcadeIconImageLabel = React.createRef();

    ClaimLimitInput = React.createRef();


    updateClaimBoxBackground = (e) =>{
        this.setState(
            { pageBgImg : this.state.pageBgImg, claimBox : e.target.files[0].name,  actionImg : this.state.actionImg,
                    invalidImg : this.state.invalidImg, failedImg : this.state.failedImg, supportImg : this.state.supportImg,
                    actionUrl: this.state.actionUrl, environment: this.state.environment, skin : this.state.skin,
                    description: this.state.description, start : this.state.start, end : this.state.end,
                    skins: this.state.skins, arcadeicon: this.state.arcadeicon
            }
        );
    }

    updateActionBackground = (e) =>{
        this.setState(
            {  pageBgImg : this.state.pageBgImg, claimBox : this.state.claimBox, actionImg : e.target.files[0].name,
                    invalidImg : this.state.invalidImg, failedImg : this.state.failedImg, supportImg : this.state.supportImg,
                    actionUrl: this.state.actionUrl, environment: this.state.environment, skin : this.state.skin,
                    description: this.state.description, start : this.state.start, end : this.state.end,
                    skins: this.state.skins, arcadeicon: this.state.arcadeicon
            }
        );
    }
    updatePageBackground = (e) =>{

        this.setState(
            {   pageBgImg : e.target.files[0].name, claimBox : this.state.claimBox, actionImg : this.state.actionImg,
                      invalidImg : this.state.invalidImg, failedImg : this.state.failedImg, supportImg : this.state.supportImg,
                      actionUrl: this.state.actionUrl, environment: this.state.environment, skin : this.state.skin,
                      description: this.state.description, start : this.state.start, end : this.state.end,
                      skins: this.state.skins, arcadeicon: this.state.arcadeicon
            }
        );
    }

    updateInvalidIdImg = (e) =>{

        this.setState(
            {   pageBgImg : this.state.pageBgImg, claimBox : this.state.claimBox, actionImg : this.state.actionImg,
                      invalidImg : e.target.files[0].name, failedImg : this.state.failedImg, supportImg : this.state.supportImg,
                      actionUrl: this.state.actionUrl, environment: this.state.environment, skin : this.state.skin,
                      description: this.state.description, start : this.state.start, end : this.state.end,
                      skins: this.state.skins, arcadeicon: this.state.arcadeicon
            }
        );
    }

    updateFailureImg = (e) =>{

        this.setState(
            {   pageBgImg : this.state.pageBgImg, claimBox : this.state.claimBox, actionImg : this.state.actionImg,
                      invalidImg : this.state.invalidImg, failedImg : e.target.files[0].name, supportImg : this.state.supportImg,
                      actionUrl: this.state.actionUrl, environment: this.state.environment, skin : this.state.skin,
                      description: this.state.description, start : this.state.start, end : this.state.end,
                      skins: this.state.skins, arcadeicon: this.state.arcadeicon
            }
        );
    }

    updateSupportImg = (e) =>{

        this.setState(
            {   pageBgImg : this.state.pageBgImg, claimBox : this.state.claimBox, actionImg : this.state.actionImg,
                      invalidImg : this.state.invalidImg, failedImg : this.state.failedImg, supportImg : e.target.files[0].name,
                      actionUrl: this.state.actionUrl, environment: this.state.environment, skin : this.state.skin,
                      description: this.state.description, start : this.state.start, end : this.state.end,
                      skins: this.state.skins, arcadeicon: this.state.arcadeicon
            }
        );
    }

    updateArcadeIconImg = (e) =>{

        this.setState(
            {   pageBgImg : this.state.pageBgImg, claimBox : this.state.claimBox, actionImg : this.state.actionImg,
                      invalidImg : this.state.invalidImg, failedImg : this.state.failedImg, supportImg : this.state.supportImg,
                      actionUrl: this.state.actionUrl, environment: this.state.environment, skin : this.state.skin,
                      description: this.state.description, start : this.state.start, end : this.state.end,
                      skins: this.state.skins, arcadeicon: e.target.files[0].name
            }
        );
    }

    updateActionURL = (actionRef) =>{
        this.setState(
                {   pageBgImg : this.state.pageBgImg, claimBox : this.state.claimBox, actionImg : this.state.actionImg,
                          invalidImg : this.state.invalidImg, failedImg : this.state.failedImg, supportImg : this.state.supportImg,
                          actionUrl: actionRef.current.value, environment: this.state.environment, skin : this.state.skin,
                          description: this.state.description, start : this.state.start, end : this.state.end,
                          skins: this.state.skins, arcadeicon: this.state.arcadeicon
                }
            );
    }
    updateSkin = (e) =>{
        let values = [...e.target.selectedOptions].map(opt => opt.value);
        this.RewardSkin = values;
        this.setState(
            {   pageBgImg : this.state.pageBgImg, claimBox : this.state.claimBox, actionImg : this.state.actionImg,
                      invalidImg : this.state.invalidImg, failedImg : this.state.failedImg, supportImg : this.state.supportImg,
                      actionUrl: this.state.actionUrl, environment: this.state.environment, skin : values,
                      description: this.state.description, start : this.state.start, end : this.state.end,
                      skins: this.state.skins, arcadeicon: this.state.arcadeicon
            }
        );
    }

    updateEnv = (e) =>{

        if(e.target.value === 'Development'){
            axios
                .post(
                    ButterParam.SkinListURL,{ gid: this.props.gid, env: 'Development'})
                .then((response) => {
                this.setState(
                    {   pageBgImg : this.state.pageBgImg, claimBox : this.state.claimBox, actionImg : this.state.actionImg,
                              invalidImg : this.state.invalidImg, failedImg : this.state.failedImg, supportImg : this.state.supportImg,
                              actionUrl: this.state.actionUrl, environment: 'Development', skin : this.state.skin,
                              description: this.state.description, start : this.state.start, end : this.state.end,
                              skins:(response.data + '').split(","), arcadeicon: this.state.arcadeicon
                    }
                );
            }, (error) => {
                console.log(error);
            }).catch(console.log);
        }else if(e.target.value === 'Live'){
            axios
                .post(
                    ButterParam.SkinListURL,{ gid: this.props.gid, env: 'Live'})
                .then((response) => {
                this.setState(
                    {   pageBgImg : this.state.pageBgImg, claimBox : this.state.claimBox, actionImg : this.state.actionImg,
                              invalidImg : this.state.invalidImg, failedImg : this.state.failedImg, supportImg : this.state.supportImg,
                              actionUrl: this.state.actionUrl, environment: 'Live', skin : this.state.skin,
                              description: this.state.description, start : this.state.start, end   : this.state.end,
                              skins:(response.data + '').split(","), arcadeicon: this.state.arcadeicon
                    }
                );
            }, (error) => {
                console.log(error);
            }).catch(console.log);
        }else if(e.target.value === 'Staging'){
            axios
                .post(
                    ButterParam.SkinListURL,{ gid: this.props.gid, env: 'Staging'})
                .then((response) => {
                this.setState(
                    {   pageBgImg : this.state.pageBgImg, claimBox : this.state.claimBox, actionImg : this.state.actionImg,
                              invalidImg : this.state.invalidImg, failedImg : this.state.failedImg, supportImg : this.state.supportImg,
                              actionUrl: this.state.actionUrl, environment: 'Staging', skin : this.state.skin,
                              description: this.state.description, start : this.state.start, end : this.state.end,
                              skins:(response.data + '').split(","), arcadeicon: this.state.arcadeicon
                    }
                );
            }, (error) => {
                console.log(error);
            }).catch(console.log);
        }
    }




    updateDescription = (descRef) =>{
        if(descRef.current.value.length >88){
            descRef.current.value = descRef.current.value.substring(0, 88);
            alert('Maximum of 88 chars allowed for description!');
            return;
        }
        this.setState(
            {   pageBgImg : this.state.pageBgImg, claimBox : this.state.claimBox, actionImg : this.state.actionImg,
                      invalidImg : this.state.invalidImg, failedImg : this.state.failedImg, supportImg : this.state.supportImg,
                      actionUrl: this.state.actionUrl, environment: this.state.environment, skin : this.state.skin,
                      description: descRef.current.value, start : this.state.start, end   : this.state.end,
                      skins: this.state.skins, arcadeicon: this.state.arcadeicon
            }
        );
    }




    updateColor = (color, reference) => {
        reference.current.value = color.hex;
    };

    getImageUploadDiv = (rowId, isMandatory, inputFileRef, inputLabelRef, labelText, imageFileName, onChangeFunc ) => {
        return (
            <Row id={rowId} className="justify-content-center">
                <Col>
                    <Badge variant={isMandatory ? (imageFileName === '' ? "danger" : "success"): (imageFileName === '' ? "secondary" : "success")}>{isMandatory ? (imageFileName === '' ? "Mandatory": "Okay"): (imageFileName === '' ?"Optional":"Okay")}</Badge>
                    <div id="" className="custom-file">
                        <input ref={inputFileRef} type="file" className="custom-file-input"
                               accept="image/png, image/jpeg, image/jpg" onChange={onChangeFunc}/>
                        <label ref={inputLabelRef} className="custom-file-label" htmlFor="customFile">{imageFileName === '' ? labelText : imageFileName}</label>
                    </div>
                </Col>
            </Row>
        );
    }


    getSingleTextInputDiv = (rowId, isMandatory, inputRef, inputId, placeholder, updateFunc, valueToCheck) => {

        if(updateFunc === null){
                return(
                    <Row id={rowId} className="justify-content-center">
                    <Col>
                        <Badge variant={isMandatory ? "danger": "secondary"}>{isMandatory ? "Mandatory": "Optional"}</Badge>
                        <div className="input-group">
                            <input ref={inputRef} id={inputId} type="text" className="form-control" placeholder={placeholder} />
                        </div>
                    </Col>
                </Row>
                );
        }

        return(
            <Row id={rowId} className="justify-content-center">
                <Col>
                    <Badge variant={isMandatory ? (valueToCheck === '' ? "danger" : "success"): (valueToCheck === '' ? "secondary" : "success")}>{isMandatory ? (valueToCheck === '' ? "Mandatory": "Okay"): (valueToCheck === '' ?"Optional":"Okay")}</Badge>
                    <div className="input-group">
                        <input ref={inputRef} id={inputId} type="text" onChange={() => updateFunc(inputRef)} className="form-control" placeholder={placeholder} />
                    </div>
                </Col>
            </Row>
        );
    }


    getColorPickingInputDiv = (rowId, isMandatory, textInputRef, colorInputRef,textInputPlaceholder, colorInputPlaceholder) =>{
        return(

            <Row id={rowId} className="justify-content-center">
                <Col>
                    <Badge variant={isMandatory ? "danger": "secondary"}>{isMandatory ? "Mandatory": "Optional"}</Badge>
                    <div className="input-group">
                        <input ref={textInputRef} type="text" className="form-control" placeholder={textInputPlaceholder} />
                        <div className="input-group">
                            <OverlayTrigger trigger="click" placement="right" rootClose={true} overlay={
                                <Popover id="popover-basic">
                                    <Popover.Title as="h3">Pick a color</Popover.Title>
                                    <Popover.Content>
                                        <SketchPicker
                                            color={colorInputRef.current != null? colorInputRef.current.value:""}
                                            onChangeComplete={(color) => this.updateColor(color,colorInputRef ) }
                                        />
                                    </Popover.Content>
                                </Popover>
                            }>
                                <input ref={colorInputRef} type="text" className="form-control" placeholder={colorInputPlaceholder} />
                            </OverlayTrigger>
                        </div>

                    </div>
                </Col>
            </Row>
        );
    }

    render(){
        return (
            <>
            <Tabs defaultActiveKey="admin" id="admin-tabs" >
                <Tab eventKey="admin" title="Admin">
                    <Jumbotron>
                        <Container id="butter-container-admin">
                            <Row className="justify-content-center">
                                {this.props.startDateBox}
                                {this.props.endDateBox}
                            </Row>
                            {this.getImageUploadDiv("butter-pagebgimg-upload", true, this.PageBackgroundImage, this.PageBackgroundImageLabel, "Upload Page Background Image", this.state.pageBgImg, this.updatePageBackground)}
                            {this.getImageUploadDiv("butter-claimimg-upload", true, this.ClaimBoxImage, this.ClaimBoxImageLabel, "Upload Claim Box Background Image", this.state.claimBox, this.updateClaimBoxBackground)}
                            {this.getImageUploadDiv("butter-actionimg-upload", true, this.ActionImage, this.ActionImageLabel, "Upload Action Image", this.state.actionImg, this.updateActionBackground)}

                            {this.getSingleTextInputDiv("butter-action-url-config",true,this.ActionEndpoint,"reward-action-box","URL to Redirect on Action", this.updateActionURL, this.state.actionUrl)}

                            <Row id="butter-reward-environment-config" className="justify-content-center">
                                <Col>
                                    <Badge variant={this.state.environment === '' ? "danger":"success"}>{this.state.environment === '' ? "Mandatory":"Okay"}</Badge>
                                    <div id="reward-environment-box" className="input-group">
                                        <Form.Control ref={this.Environment} onChange={this.updateEnv} as="select">
                                            <option disabled={true} defaultValue={true} >Select the Environment for the reward</option>
                                            <option>Live</option>
                                            <option>Staging</option>
                                            <option>Development</option>
                                        </Form.Control>
                                    </div>
                                </Col>
                            </Row>
                            <Row id="butter-reward-selection-config" className="justify-content-center">
                                <Col>
                                    <Badge variant={this.state.skin === '' ? "danger":"success"}>{this.state.skin === '' ? "Mandatory":"Okay"}</Badge>
                                    <div className="input-group">
                                        <Form.Control disabled={this.state.skins.length === 0}  as="select" multiple onChange={this.updateSkin}>
                                            disabled={
                                            this.state.skins.length === 0
                                                ? <option disabled={true} defaultValue={true}>{"Select Environment Above to Enable Multiple Selection for Skins (Command+Click)"}</option> :
                                                <></>}
                                            {this.state.skins.map((txt, i) => <option key={i}>{txt}</option>)}
                                        </Form.Control>
                                    </div>
                                </Col>
                            </Row>

                            {this.getSingleTextInputDiv("butter-description-config",true,this.Description,"reward-description-box","Short Description to display to players", this.updateDescription, this.state.description)}

                            <hr style={{ color: 'black', backgroundColor: 'black', height: 2 }}/>
                            {this.getSingleTextInputDiv("butter-claim-limit",false,this.ClaimLimitInput,"butter-claim-limit-box","Claim Limit (i.e only first X claims are allowed) - Enter a number", null, '')}


                            {this.getSingleTextInputDiv("butter-supportid-placeholder-config",false,this.SupportIdPlaceholder,"supportid-placeholder-box","Placeholder for SupportID Input to display to players", null, '')}



                            {this.getColorPickingInputDiv("butter-claim-button-config", false,this.ClaimButtonText,this.ClaimButtonColor,"Text for claim button to display to players","Color for claim button to display to players"  )}
                            {this.getColorPickingInputDiv("butter-claim-success-config", false,this.ClaimSuccessText,this.ClaimSuccessColor,"Text for a successful claim to display to players","Color of the success text to display to players"  )}
                            {this.getColorPickingInputDiv("butter-claim-failure-txt-config", false,this.ClaimFailureText,this.ClaimFailureColor,"Text for a failed claim to display to players","Color of the failure text to display to players"  )}
                            {this.getSingleTextInputDiv("butter-custom-rewardurl",false,this.CustomURL,"customurl-box","Customized URL for the reward", null, '')}



                            {this.getImageUploadDiv("butter-invalid-img-upload", false, this.SupportIdInvalidImage, this.SupportIdInvalidImageLabel, "Upload Invalid SupportID Static Image", this.state.invalidImg, this.updateInvalidIdImg)}
                            {this.getImageUploadDiv("butter-failed-img-upload", false, this.ClaimFailureImage, this.ClaimFailureImageLabel, "Upload Failed Claim Static Image", this.state.failedImg, this.updateFailureImg)}
                            {this.getImageUploadDiv("butter-supporthelp-img-upload", false, this.SupportHowTo, this.SupportHowToLabel, "Upload 'How to Find SupportID' Static Image", this.state.supportImg, this.updateSupportImg)}

                            {this.getImageUploadDiv("butter-arcadelink-img-upload", false, this.ArcadeIconImage, this.ArcadeIconImageLabel, "Upload an Icon with link to Apple Arcade", this.state.arcadeicon, this.updateArcadeIconImg)}


                            <hr style={{ color: 'black', backgroundColor: 'black', height: 2}} />

                            <Row className="justify-content-center">
                                <div className="input-group">
                                    <input id="retrieve-reward-box" type="text" className="form-control" ref={this.RewardRetrieve} placeholder="Reward Id To Retrieve Details"/>
                                    <button onClick={() => this.props.rewardRetrieve(this.RewardRetrieve.current.value)} className="btn btn-light">Retrieve</button>
                                </div>
                            </Row>
                            <hr style={{ color: 'black', backgroundColor: 'black', height: 2}} />
                            <Row className="justify-content-center">
                                <button onClick={this.props.rewardSubmit} className="btn btn-dark">Create</button>
                            </Row>
                            <Row className="justify-content-center">
                                <LoadingIndicator/>
                            </Row>
                            <LinkToShare address={this.props.link}/>
                        </Container>
                    </Jumbotron>
                </Tab>
                <Tab eventKey="stat" title="Stats">
                    <RewardStats gid={this.props.gid}/>
                </Tab>
                <Tab eventKey="config" title="Configs" >
                    <Configuration gid={this.props.gid} />
                </Tab>
            </Tabs>
        {Butter.getCopyRightContent()}
        </>
        );
    }
}


export default AdminPageV2;