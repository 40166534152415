import React from 'react';
import {Col, Row} from "react-bootstrap";
import * as ButterParam from "../util/butterparam";


//TODO - better naming
class ClaimV2 extends React.Component {
    getInfoLabel = () =>{
        if(this.props.resultCode === ButterParam.ClaimSuccess){
            return this.props.layout.claimSuccessText === undefined ? "Claim Success!" : this.props.layout.claimSuccessText;
        }else if(this.props.resultCode === ButterParam.LinkAlreadyClaimed){
            return ButterParam.AlreadyClaimedResult;
        }else if(this.props.resultCode === ButterParam.SkinAlreadyInInbox){
            return ButterParam.RewardInInboxResult;
        }else if(this.props.resultCode === ButterParam.SkinOrPlayerInvalid){
            return ButterParam.InvalidIdResult;
        }else if(this.props.resultCode === ButterParam.ServerBusyError){
            return ButterParam.ServerErrorResult;
        }else if(this.props.resultCode === ButterParam.PlayerNeverOnlineError){
            return ButterParam.PlayerNotOnlineResult;
        }

    }

    getLabelColor = () =>{
        if(this.props.resultCode === ButterParam.ClaimSuccess){
            return this.props.layout.claimSuccessColor === undefined ? ButterParam.FontColorPositive : this.props.layout.claimSuccessColor;
        }else{
            return this.props.layout.claimFailedTextColor === undefined ? ButterParam.FontColorNegative : this.props.layout.claimFailedTextColor;
        }
    }


    render() {
        return (
            <div style={{ height: '430px',  backgroundSize: 'cover',  backgroundRepeat: 'no-repeat',
                backgroundImage: `url(${this.props.layout.pageBackground})`}}>
                <Row className="justify-content-center" >
                    <div style={{height: '375px',backgroundSize: 'contain',  backgroundRepeat: 'no-repeat', backgroundImage: `url(${this.props.layout.claimBox})`}}>
                        <Row className="justify-content-center">
                            <button style={{width:ButterParam.MaxImageWidth, height: '160px', opacity:'0'}} disabled></button>
                        </Row>
                        <Col>
                            <Row className="justify-content-center">
                                <button style={{width:ButterParam.MaxImageWidth, height: '25px', opacity:'0'}} disabled></button>
                            </Row>
                            <Row className="justify-content-center">
                                <label style={{
                                    textAlign:'center',
                                    width:'320px',
                                    fontFamily: ButterParam.ClaimFontFamily,
                                    fontWeight:ButterParam.ClaimFontWeight,
                                    color:ButterParam.FontColorNormal,
                                    fontSize: this.props.layout.description.length <= 23 ? '20px':this.props.layout.description.length <= 57? '14px':'12px',
                                    wordWrap:'break-word'
                                }}>
                                    {this.props.layout.description}
                                </label>
                            </Row>
                            <Row className="justify-content-center">
                                <label style={{ fontFamily: ButterParam.ClaimFontFamily,fontWeight:ButterParam.ClaimFontWeight, color:ButterParam.FontColorLighter, fontSize: '12px' }}>
                                    Expiring on {new Date(this.props.layout.endDate).toDateString()}
                                </label>
                            </Row>
                            <Row className="justify-content-center">
                                <button style={{width:ButterParam.MaxImageWidth, height: '25px', opacity:'0'}} disabled></button>
                            </Row>
                            <Row className="justify-content-center">
                                {this.props.isIphone && this.props.layout.deeplink ? '': <input
                                    ref={this.props.supportVal}
                                    disabled={this.props.submitted === true}
                                    style={{
                                        fontFamily: ButterParam.ClaimFontFamily,
                                        fontColor: ButterParam.FontColorNormal,
                                        fontWeight:ButterParam.ClaimFontWeight,
                                        backgroundImage:`url('input_field.png')`,
                                        fontSize: '12px',
                                        textAlign: 'center',
                                        border: 'none',
                                        width:'330px',
                                        height: '40px',
                                        borderRadius:'8px',
                                        backgroundRepeat: 'round',
                                        backgroundColor: ButterParam.FontColorLighter
                                    }} type="text" placeholder={this.props.layout.remainingclaims == '0' ?"Rewards Fully Claimed!" :this.props.layout.supportIdPlaceholder === undefined ? "Enter your Support ID" : this.props.layout.supportIdPlaceholder}/>}
                            </Row>
                            <Row className="justify-content-center">
                                <button style={{width:ButterParam.MaxImageWidth, height: '2px', opacity:'0'}} disabled></button>
                            </Row>
                            <Row className="justify-content-center" >
                                {this.props.submitted  === true?
                                    <button
                                        onClick={this.props.handleSubmission}
                                        className="btn"
                                        style={
                                                {
                                                    fontFamily: ButterParam.ClaimFontFamily,
                                                    fontWeight:ButterParam.ClaimFontWeight,
                                                    color:this.getLabelColor(),
                                                    fontSize: '20px',
                                                    textAlign: 'center',width:'330px', height: '40px',
                                                    border: 'none', backgroundRepeat: 'round',
                                                    borderRadius:'8px'
                                                }

                                        }
                                        disabled
                                    >
                                        {this.getInfoLabel()}
                                    </button>
                                    :
                                    <button
                                        ref={this.props.buttonRef}
                                        onClick={this.props.handleSubmission}
                                        className="btn"
                                        style={
                                                this.props.layout.claimButtonColor === undefined ?
                                                    {
                                                        fontFamily: ButterParam.ClaimFontFamily,
                                                        fontWeight:ButterParam.ClaimFontWeight,
                                                        color:'white',
                                                        fontSize: '20px',
                                                        textAlign: 'center',width:'330px', height: '40px',
                                                        border: 'none', backgroundRepeat: 'round',
                                                        backgroundImage:`url('btn_green.png')`,
                                                        borderRadius:'8px'
                                                    }:
                                                    {
                                                        fontFamily: ButterParam.ClaimFontFamily,
                                                        fontWeight:ButterParam.ClaimFontWeight,
                                                        fontSize: '20px',
                                                        textAlign: 'center',width:'330px', height: '40px',
                                                        border: 'none', backgroundRepeat: 'round',
                                                        backgroundColor: this.props.layout.claimButtonColor,
                                                        borderRadius:'8px'
                                                    }

                                        }
                                    >
                                        {this.props.layout.claimButtonText === undefined ? "Claim!" : this.props.layout.claimButtonText}
                                    </button>
                                }
                            </Row>
                            <Row className="justify-content-center">
                                <button style={{width:ButterParam.MaxImageWidth, height: '5px', opacity:'0'}} disabled></button>
                            </Row>
                        </Col>
                    </div>
                </Row>
            </div>
        );
    }
}

export default ClaimV2;