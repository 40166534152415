import axios from "axios";
import crypto from "crypto";
import Recaptcha from "react-google-invisible-recaptcha";
import React from "react";
import CopyrightDiv from "../components/copyright";
import ErrorDiv from "../components/error";
import * as ButterParam from "./butterparam";
import { validate as uuidValidate } from 'uuid';
import ClaimV2 from "../components/claimv2";
import SupportV2 from "../components/supportv2";
import ClaimSuccess from "../components/claimsuccess";
import InvalidSupportID from "../components/invalidid";
import Linkimage from "../components/linkimage";



class ButterRoyale{



    //TODO - move this to env vars in docker
    recaptchaSiteKey = "6Lc7zKUZAAAAAECQ48B1RIEq_v_figDx679B_rU6";//https://www.google.com/recaptcha/admin
    CopyRightText ="All Rights Reserved - Mighty Bear Games©";

    RewardId = "N/A";
    SupportIDRef = React.createRef();
    ClaimButtonRef = React.createRef();
    ButterSkinRef = "";
    Env = ''
    DeepLinks = true;




    LayoutDetails = {};
    prepareRecaptcha = (callback) =>{
       return( <Recaptcha
            ref={ ref => this.recaptcha = ref }
            sitekey={ this.recaptchaSiteKey }
            onResolved=
                {
                    () =>
                    {
                        if(this.SupportIDRef.current !== null && !uuidValidate(this.SupportIDRef.current.value)){
                            this.SupportIDRef.current.style.backgroundColor = ButterParam.AdminParams.MissingFieldColor;
                            if(this.SupportIDRef.current.value.includes('-')){
                                alert("Your support ID seems incorrect!.\nPlease copy your Support ID and try again.");
                            }else{
                                alert("Your support ID seems incorrect!.\nDid you forget the dashes?");
                            }
                            this.recaptcha.reset();
                        }else{
                            //Safe to redirect
                            if(this.SupportIDRef.current !== null){
                                this.SupportIDRef.current.style.backgroundColor = "";
                            }
                            if(this.DeepLinks === true){
                                console.log("Deeplinks are enabled!")
                                if((window.navigator.appVersion.indexOf('iPhone') !== -1)){
                                    this.openDeeplink()
                                }else{
                                    this.sendData({supportId:this.SupportIDRef.current.value},callback )
                                }
                            }else{
                                console.log("Deeplinks are disabled!")
                                this.sendData({supportId:this.SupportIDRef.current.value},callback )
                            }

                        }
                    }
                }
            onError = {
                (err) => {
                    console.log( `Recaptcha Error ${err}` );
                }
            }
            onExpired = {
                () =>
                {
                    this.recaptcha.reset();
                }
            }
        />);
    }

    addEmptyLines = (amount) =>{
        let lines = []
        for (let i = 0; i < amount; i++) {
            lines.push(<br key={i} />);
        }

        return lines;
    }

    openDeeplink = () =>{
        window.location.replace("mbg://butter/launch?reward=" + this.RewardId + "&env=" + this.Env+"&skin="
            +this.ButterSkinRef);
    }

    sendData = ({supportId}, success) =>{
        if(this.RewardId !== 'N/A'){
            let supportRewardHash = crypto
                .createHash("sha256")
                .update(supportId +''+  this.RewardId)
                .digest()
                .toString("base64")

            axios.post(ButterParam.SubmitClaimURL,
                {
                    supportRewardHash : supportRewardHash,
                    rewardId: this.RewardId,
                    skin: this.ButterSkinRef,
                    supportId : supportId,
                    env: this.Env})
                .then(res=>{
                    if(res.data.result === 'Success'){
                        success(200);
                    }else{
                        if(res.data.reason === 'AlreadyClaimed'){
                            success(ButterParam.LinkAlreadyClaimed);
                        }else if(res.data.reason === 'InvalidSkinOrPlayer'){
                            success(ButterParam.SkinOrPlayerInvalid);
                        }else if(res.data.reason === 'SkinAlreadyInInbox'){
                            success(ButterParam.SkinAlreadyInInbox);
                        }else if(res.data.reason === 'NotOnline'){
                            success(ButterParam.PlayerNeverOnlineError);
                        }else{
                            success(ButterParam.ServerBusyError);
                            console.log("Some Other error");
                        }
                    }
                });
        }
    }

    extractRewardId =  () => {
        var searchParams = new URLSearchParams(window.location.search);
        if(searchParams && searchParams.get("r")){
            try{
                this.RewardId =  searchParams.get("r");
                return axios.post(ButterParam.RewardRetrieveURL,{
                    rewardId: this.RewardId
                });
            }catch (exception) {
                console.log("Invalid Reward - " + searchParams)
                return new Promise((resolve, reject) =>{resolve("")});
            }
        }else{
           return new Promise((resolve, reject) =>{resolve("")});
        }

    }

    getErrorContent = () =>{
        return(
            <ErrorDiv />
        );
    }

    getClaimV2 = (handleSubmission, isClaimSubmitted, pageLayout, processCode) =>{
        //TODO add layout processing
        this.ButterSkinRef = pageLayout.rewardSkin;
        let isIOSDevice = (window.navigator.appVersion.indexOf('iPhone') !== -1);
        return(
            <ClaimV2 buttonRef={this.ClaimButtonRef} isDeeplink={pageLayout.deeplink} isIphone = {isIOSDevice} handleSubmission = {handleSubmission} submitted = {isClaimSubmitted} layout={pageLayout} supportVal = {this.SupportIDRef} resultCode={processCode}/>
        );
    }

    getSupportV2 = (pageLayout) =>{
        return(
            <SupportV2 layout={pageLayout}/>
        );
    }
    getIcon = (pageLayout) =>{
        return (<Linkimage iconurl={pageLayout.appstoreicon} layout={pageLayout}/>);
    }
    getClaimSuccess = (pageLayout, handleAction) =>{
            ButterParam.AdminParams.TrackEventData(pageLayout.environment, 'ClaimSuccess',this.RewardId, pageLayout.rewardSkin)
        return(
            <ClaimSuccess layout={pageLayout} handleAction ={handleAction} />
        );
    }
    getInvalidSupportId = (showSupport) =>{
        return(
            <InvalidSupportID showSupport={showSupport}/>
        );
    }


    getClaimErrorInfo = (code, showSupport, pageLayout) =>{

        if(code === ButterParam.SkinOrPlayerInvalid){
                ButterParam.AdminParams.TrackEventData(pageLayout.environment,'InvalidSupportId', this.RewardId, pageLayout.rewardSkin)
            return this.getInvalidSupportId(showSupport);
        }else if(code === ButterParam.LinkAlreadyClaimed){
                ButterParam.AdminParams.TrackEventData(pageLayout.environment,'RewardAlreadyClaimed', this.RewardId,  pageLayout.rewardSkin)
            alert(`${ButterParam.LinkAlreadyClaimedText1}\n${ButterParam.LinkAlreadyClaimedText2}`);
        }else if(code === ButterParam.SkinAlreadyInInbox){
                ButterParam.AdminParams.TrackEventData(pageLayout.environment,'RewardAlreadyInInbox', this.RewardId, pageLayout.rewardSkin)
             alert(`${ButterParam.SkinAlreadyInInbox1}\n${ButterParam.SkinAlreadyInInbox2}`);
        }else if(code === ButterParam.ServerBusyError){
            alert("We are having trouble to process your claim.\nPlease contact support@mightybeargames.com");
        }else if(code === ButterParam.PlayerNeverOnlineError){
            alert("In order to claim a reward, you should enable online games!\nPlease open the game and enable the online games modes, and then try to claim again!");
        }
        return this.getSupportV2(pageLayout);
    }

    getCopyRightContent = () =>{
        return (
            <CopyrightDiv copyright={this.CopyRightText}></CopyrightDiv>
        );
    }




}


export default ButterRoyale;