import React from 'react';
import ReactDOM from 'react-dom';
import ButterRoyale from "./util/butterroyale";
import * as ButterParam from "./util/butterparam";
import {Switch,Route,BrowserRouter } from "react-router-dom";
import "./css/Main.css";
import LoginSuccess from "./components/loginsuccess";
import LoginFail from "./components/loginfail";
import AdminPageV2 from "./components/adminpagev2";
import ReactPixel from "react-facebook-pixel";
import Linkimage from "./components/linkimage";


const Butter = new ButterRoyale();

class RewardPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rewardClaimed: false, inputActive: true, showAction: false,
            claimError : false, processCode : 0,  layout : {} }
    }
    componentDidMount() {
        Butter
            .extractRewardId()
            .then(
                (layoutInfo) =>{
                    ReactPixel.init(ButterParam.AdminParams.FBTrackingPixelID)
                    let isExpired = (new Date() > new Date(JSON.parse(layoutInfo.data.message).Item.info.endDate));
                    if(JSON.parse(layoutInfo.data.message).Item !== undefined){
                        if(isExpired){
                                ButterParam
                                    .AdminParams
                                    .TrackEventData(
                                        JSON.parse(layoutInfo.data.message).Item.info.environment,
                                        'ExpiredRewardLink',
                                        JSON.parse(layoutInfo.data.message).Item.rewardid,
                                        JSON.parse(layoutInfo.data.message).Item.info.rewardSkin)
                            alert('The reward is expired!');
                        }else{
                                ButterParam
                                    .AdminParams
                                    .TrackEventData(
                                        JSON.parse(layoutInfo.data.message).Item.info.environment,
                                        'RewardPageView',
                                        JSON.parse(layoutInfo.data.message).Item.rewardid,
                                        JSON.parse(layoutInfo.data.message).Item.info.rewardSkin)

                        }
                        let layoutinfo = JSON.parse(layoutInfo.data.message).Item.info;
                        layoutinfo.deeplink = JSON.parse(layoutInfo.data.message).deeplinks;
                        this.setState({
                                rewardClaimed: isExpired,
                                inputActive: !isExpired,
                                showAction: this.state.showAction,
                                claimError : this.state.claimError,
                                processCode : 0,
                                layout : layoutinfo
                            });
                        if(layoutinfo.remainingclaims == '0'){
                            ButterParam
                                .AdminParams
                                .TrackEventData(
                                    JSON.parse(layoutInfo.data.message).Item.info.environment,
                                    'AccessAfterClaimLimit',
                                    JSON.parse(layoutInfo.data.message).Item.rewardid,
                                    JSON.parse(layoutInfo.data.message).Item.info.rewardSkin)
                            this.setState({
                                rewardClaimed: true,
                                inputActive: false,
                                showAction: this.state.showAction,
                                claimError : this.state.claimError,
                                processCode : 0,
                                layout : layoutinfo
                            });
                            alert("This reward has been fully claimed!\nPlease try other links to claim!");
                        }
                        Butter.DeepLinks = JSON.parse(layoutInfo.data.message).deeplinks;
                        Butter.Env = JSON.parse(layoutInfo.data.message).Item.info.environment;

                    }
                }
            ).catch(console.error);
    }


    updateClaimStatus = (code) => {
        this.setState(
            {   rewardClaimed: true, inputActive: false, showAction: code === ButterParam.ClaimSuccess,
                      claimError: code !== ButterParam.ClaimSuccess, processCode: code, layout : this.state.layout
            }
        );
    }


    handleSubmission = () => {

        if(Butter.SupportIDRef.current === null){
            Butter.ClaimButtonRef.current.disabled = true;
            Butter.recaptcha.execute();
        }else if (Butter.SupportIDRef.current.value) {
            Butter.recaptcha.execute();
        }


    }

    showSupport = () => {
        this.setState(
            {   rewardClaimed: false, inputActive: this.state.inputActive, showAction: false,
                      claimError : false, processcode : ButterParam.ClaimSuccess, layout : this.state.layout
            }
        );
    }


    handleActionButton = () => {
        this.setState(
            {   rewardClaimed: this.state.rewardClaimed, inputActive: this.state.inputActive,
                      showAction: this.state.showAction, claimError : this.state.claimError,
                      processcode : this.state.processCode, layout : this.state.layout
            }
        );
         window.open(this.state.layout.actionUrl, '_blank');
    }


    render() {
        return (
            <>
                {ButterParam.EnvironmentFlagBadge}
                {
                            (Butter.RewardId === "N/A")?
                                Butter.getErrorContent()
                                : <>
                                    {Butter.getClaimV2(this.handleSubmission,this.state.rewardClaimed, this.state.layout, this.state.processCode)}

                                    {    this.state.showAction ?
                                            Butter.getClaimSuccess(this.state.layout, this.handleActionButton) :
                                            this.state.claimError ?
                                                Butter.getClaimErrorInfo(this.state.processCode, this.showSupport,this.state.layout) :
                                                Butter.getSupportV2(this.state.layout)
                                    }
                                    {Butter.prepareRecaptcha(this.updateClaimStatus)}
                                </>
                        }
                {Butter.getIcon(this.state.layout)}
                {Butter.getCopyRightContent()}
            </>

        );
    }
}

// ========================================

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route exact path='/' component={RewardPage}/>
            <Route path='/admin' component={AdminPageV2}/>
            <Route path='/loginsuccess' component={LoginSuccess}/>
            <Route path='/loginfail' component={LoginFail}/>
        </Switch>
    </BrowserRouter>,
    document.getElementById('root')
);
